import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const ArtDirection = makeShortcode("ArtDirection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`On the web,
`}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#Art_direction"
        }}>{`art direction`}</a>{`
refers to changing the image rendered at different display sizes. The
`}<inlineCode parentName="p">{`ArtDirection`}</inlineCode>{` component allows you to provide multiple images achieve this
goal.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <ArtDirection mdxType="ArtDirection">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.29166666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAcedYA//xAAYEAEAAwEAAAAAAAAAAAAAAAABAhEgMf/aAAgBAQABBQI6xox//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxABAAMAAAAAAAAAAAAAAAAAAQAgMv/aAAgBAQAGPwKaGv8A/8QAGBABAAMBAAAAAAAAAAAAAAAAAQARYSD/2gAIAQEAAT8hFgUNYVhiOf/aAAwDAQACAAMAAAAQs8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAACAQUAAAAAAAAAAAAAAAABEQAgITGRsf/aAAgBAQABPxAAhSbsQ1GIjyl8p//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mobile image",
          "title": "Mobile image",
          "src": "/static/5f7834458ce96d6084183dd6b544432d/2e753/mobile.jpg",
          "srcSet": ["/static/5f7834458ce96d6084183dd6b544432d/69549/mobile.jpg 288w", "/static/5f7834458ce96d6084183dd6b544432d/473e3/mobile.jpg 576w", "/static/5f7834458ce96d6084183dd6b544432d/2e753/mobile.jpg 1152w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.29166666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAcedYA//xAAYEAACAwAAAAAAAAAAAAAAAAAAAQIRIP/aAAgBAQABBQIcaz//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAEAAwAAAAAAAAAAAAAAAAABACAy/9oACAEBAAY/Apoa/wD/xAAZEAEBAAMBAAAAAAAAAAAAAAABEQAgYSH/2gAIAQEAAT8hPWWdwhTiNf/aAAwDAQACAAMAAAAQM8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEBAAMBAAAAAAAAAAAAAAABEQAgMbH/2gAIAQEAAT8QIKAWK4ZRksqr5r//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tablet image",
          "title": "Tablet image",
          "src": "/static/e6054767c240469eea72a2b02940941c/2e753/tablet.jpg",
          "srcSet": ["/static/e6054767c240469eea72a2b02940941c/69549/tablet.jpg 288w", "/static/e6054767c240469eea72a2b02940941c/473e3/tablet.jpg 576w", "/static/e6054767c240469eea72a2b02940941c/2e753/tablet.jpg 1152w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.29166666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAciVYA//xAAYEAEAAwEAAAAAAAAAAAAAAAABAhEgIf/aAAgBAQABBQILWPMf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAQUAAAAAAAAAAAAAAAAAAAIRIDEy/9oACAEBAAY/ArY0mP8A/8QAGRABAAIDAAAAAAAAAAAAAAAAARFhACAh/9oACAEBAAE/IeAizgEpqHX/2gAMAwEAAgADAAAAEHPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAAEFAQAAAAAAAAAAAAAAAREAICExUXH/2gAIAQEAAT8QKAUPGlQSGxL5i3//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Desktop image",
          "title": "Desktop image",
          "src": "/static/c7b03eb575e60d6e73a360765d60f8eb/2e753/desktop.jpg",
          "srcSet": ["/static/c7b03eb575e60d6e73a360765d60f8eb/69549/desktop.jpg 288w", "/static/c7b03eb575e60d6e73a360765d60f8eb/473e3/desktop.jpg 576w", "/static/c7b03eb575e60d6e73a360765d60f8eb/2e753/desktop.jpg 1152w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </ArtDirection>
    <h2>{`Code`}</h2>
    <p>{`You can place up to three images inside of the ArtDirection component. The first
will be used for mobile, the second for tablet, and the third for desktop. If
only two images are provided, the second image will be used for both tablet and
desktop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ArtDirection.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ArtDirection",
        "path": "components/ArtDirection.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ArtDirection"
      }}>{`<ArtDirection>

![Mobile image](./mobile.jpg)

![Tablet image](./tablet.jpg)

![Desktop image](./desktop.jpg)

</ArtDirection>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      